.App {
  text-align: center;
}

@media only screen and (min-width: 800px) {
  img {
    width: 400px;
    height: 400px;
  }

  p {
    width: 800px;
  }
}

@media only screen and (max-width: 800px) {
  img {
    width: 100%;
    height: auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}